
import { defineComponent, ref, nextTick } from "vue";
export default defineComponent({
  name: "TopicComment",
  props: {
    topic: {
      type: Object,
      default: () => {
        return {};
      },
    },
    modelValue: {
      type: String,
      default: "",
    },
    isFav: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const textarea = ref<HTMLTextAreaElement | null>(null);
    const isWriting = ref(false);
    function showForm() {
      isWriting.value = true;
      nextTick(() => {
        if (textarea.value) {
          textarea.value.focus();
        }
      });
    }
    function hideForm() {
      isWriting.value = false;
      if (textarea.value) {
        textarea.value.blur();
      }
    }
    const text = ref("");

    function delText() {
      text.value = "";
    }
    function submit() {
      ctx.emit("submit", delText);
      hideForm();
    }
    function handleInput(e: Event) {
      ctx.emit("update:modelValue", (e.target as HTMLTextAreaElement).value);
    }
    function star() {
      ctx.emit("star");
    }
    function onFav() {
      const isFav = !!props.isFav;
      ctx.emit("collect", isFav);
    }
    return {
      isWriting,
      showForm,
      hideForm,
      submit,
      handleInput,
      star,
      text,
      onFav,
      textarea,
    };
  },
});
