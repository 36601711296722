
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "Recommend",
  emits:['star'],
  props: {
    likeCount: Number,
    likeUser: Array,
    likeUserCount: Number,
  },
  setup(props, ctx) {
    function onStar() {
      ctx.emit("star");
    }
    return {
      onStar,
    };
  },
});
